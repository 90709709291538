<template>
  <div>
    <div class="container">
      <el-card style="margin-top: 20px">
        <div class="input-with">
          <el-button type="primary" @click="addbanner()">新增</el-button>
          <el-button type="info" @click="carouselDelete" plain>批量删除</el-button>
        </div>
        <div style="float: right">
          <div class="seach_box">
            <el-input
              placeholder="请输入关联场景名称"
              v-model="queryInfo.name"
              class="input-with-select"
            >
              <el-button class="seach" slot="append" @click="changeSearchangeSearch"
                >搜索</el-button
              >
            </el-input>
          </div>
        </div>
        <el-table
          v-loading="loading"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          :data="listData"
          stripe
          style="width: 100%"
          class="in_table"
          :row-key="getRowKey"
          @selection-change="handleSelectionChange"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column type="selection" width="80px" align="center" :reserve-selection="true" />

          <el-table-column label="建议编号" width="100px" align="center" >
            <template slot-scope="scope">
            {{ (scope.$index+1)+(queryInfo.pageNum-1)*queryInfo.pageSize }}
          </template>
          </el-table-column>

          <el-table-column label="建议描述" prop="suggestionDescribe" align="center">
            <template v-slot="{ row }">
              <span>{{ row.suggestionDescribe }}</span>
            </template>
          </el-table-column>
          <el-table-column label="企业名称" prop="companyFullName" align="center">
            <template v-slot="{ row }">
             <span>{{ row.companyFullName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="企业行为" prop="sceneData" align="center">
            <template v-slot="{ row }">
              <span>{{ row.behaviorData}}</span>
            </template>
          </el-table-column>
          <el-table-column label="应用场景" prop="behaviorData" align="center">
            <template v-slot="{ row }">
              <span>{{ row.sceneData }}</span>
            </template>
          </el-table-column>
          <el-table-column label="推送时间" width="180px" prop="createTime"  align="center">
            <template v-slot="{ row }">
              <span>{{ row.createTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="" align="center">
            <template v-slot="{ row }">

              <span v-if="row.isShelf==0"  class="frozen" @click="checkClick(row)">下架</span>
              <span v-else  class="frozen" @click="checkClick(row)">上架</span>
           
              <span class="edit" @click="editclick(row)">编辑</span>
              
                <span class="frozen" @click="carouselActivate(row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>

    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>
  
  <script>
import { selectPresalesSuggestionPage,updateIsShelf,deleteByIds } from '@/api/recommendations'

import { pagination } from '@/mixins/pagination'

const defaultQueryInfo = Object.freeze({
  name: '',
  orderByParam: '',
  pageNum: 1,
  pageSize: 10
})
export default {
  name: 'recommendations',
  mixins: [pagination],
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      DialogVisible: false,
      ids:[],
      listrole: []
    }
  },
  created() {
    this.search()
  },
  methods: {
    //查询一览数据
    async search() {
      this.loading = true
      const { data: res } = await selectPresalesSuggestionPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.listData = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },

    getRowKey(row) {
      return row.id
    },
    //多选
    handleSelectionChange(selection) {
      
      this.ids = selection.map((item) => item.id)
    
    },
    //批量发短信
    checkClick(row) {
     const query={
      id:row.id,
      value:row.isShelf==0?'1':'0'
     }
     query.value=Number(query.value)
        this.$alert('是否确认操作？')
          .then(function () {
            return updateIsShelf(query)
          })
          .then(() => {
            this.search()
          
            this.$message.success('操作成功')
          })
          .catch(() => {})
    },
    //新增banner
    addbanner() {
      this.$router.push({
        name: 'addrecommend'
      })
    },
    //编辑
    editclick(row) {
      this.$router.push({
        name: 'addrecommend',
        query: {
          id: row.id
        }
      })
    },

    carouselDelete() {
     if(!this.ids.length){
      this.$message.error('请选择建议！');
     
      return
     }
      let ids=this.ids
      this.$alert('您是否确认删除？')
        .then(function () {
          return deleteByIds(ids)
        })
        .then(() => {
          this.search()
          this.$message.success('删除成功')
        })
        .catch(() => {})
    },
    //删除
    carouselActivate(row) {
      let    ids =[row.id]
     
      
      this.$alert('您是否确认删除？')
        .then(function () {
          return deleteByIds(ids)
        })
        .then(() => {
          this.search()
          this.$message.success('删除成功')
        })
        .catch(() => {})
    },

    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    // 搜索
    changeSearchangeSearch() {
      this.queryInfo.pageNum = 1
      this.search()
    }
  }
}
</script>
  
  <style lang="less" scoped>
.container {
  height: calc(100vh - 150px);
  overflow: auto;
}
.seach_box {
  display: flex;
  margin: 15px 0;

  .input-with-select {
    width: 400px;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}
.office {
  display: inline-block;
  background: #edf4ff;
  border: 1px solid #559eff;
  color: #4e93fb;
  border-radius: 2px;
  font-size: 12px;
  padding: 0px 3px;
}
.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}

.input-with {
  width: 100%;
  overflow: hidden;

  .input-with-select {
    width: 400px;
    float: right;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}

.in_table {
  width: 100%;
  margin-top: 20px;

  .img_url {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
}
.edit {
  padding-left: 20px;
  color: #4e93fb;
  cursor: pointer;
}
.frozen {
  padding-left: 20px;
  color: #fd5469;
  cursor: pointer;
}

.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
</style>
  